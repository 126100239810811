import { SkyKickUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { SyndicationEnvironment } from './syndication-environment';

export const environment: SyndicationEnvironment = {
    production: false,
    userProvider: SkyKickUserProvider,
    papi: 'https://sk-dev4-papi.skykick.com',
    sewr: 'https://route.skykick.com',
    webCore: 'https://sk-web-core-dev0.azurewebsites.net',
    licensesAssignmentBaseAddress: 'https://sk-dev-platform-apis.skykick.com'
};
